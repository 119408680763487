<template>
  <div class="page-content">
    <page-breadcrumb title="Learning paths" class="mb-2" />

    <data-table-ssr id="item_list" ref="item_list"
      :get-list-fn="getList" :columns="item_fields"
      :to-edit-fn="toEditPage"
    />
  </div>
</template>

<script>
import { removeEmpty, safeGet, buildSelectOptions } from '@core/utils/index';
import service from '../service';

const english_level_opts = [];
const create_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Title', field: 'title', input_type: 'ui-component' },
      { label: 'English level', field: 'english_level', input_type: 'select', options: english_level_opts, validate: { required: true }, },
    ]
  }
];

const item_fields = [
  { label: 'Title', field: 'title', display_type: 'translatable-text' },
  { label: 'English level', field: 'english_level', input_type: 'select', options: english_level_opts, validate: { required: true }, },
];

export default {
  data(){
    return{
      create_schema,
      item_fields,
      total: 0,
    }
  },
  methods:{
    async createItem(data) {
      let new_item = await service.create(data);
      return new_item;
    },
    async getList({ limit, page, query }) {
      query = removeEmpty(query);
      let response = await service.getList({
        query: encodeURIComponent(JSON.stringify(query)),
        page,
        limit,
        populate: {path: 'topic', select: 'name'}
      });
      let list = []; let total = 0;
      if (response.data.type === "DATA") {
        list = response.data.data.list;
        total = response.data.data.total;

        let levels = safeGet(() => buildSelectOptions(response.data.data.meta['ENGLISH_LEVEL'])) || [];
        english_level_opts.length = 0;
        english_level_opts.push(...levels);
      }
      return { list, total };
    },
    async updateItem(row) {
      await service.update({ data: JSON.stringify(row) });
      // this.$refs.item_list.getList();
    },
    async toEditPage({ _id }) {
      this.$router.push({ name: 'learning_path-detail', params: { id: _id } });
    },
    async deleteItem({ _id }) {
      await service.delete({ id: _id });
    },
  }
}
</script>
